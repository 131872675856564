import * as React from 'react'
import PubSub from 'pubsub-js'
import { useLocation } from 'react-router-dom'
import analytics from 'utils/analytics'
import Button from 'components/atomics/atoms/button'
import Checkbox from 'components/atomics/atoms/checkbox'
import provinces from 'components/atomics/atoms/province-select/provinces.json'
import Input from 'components/atomics/atoms/input'
import { useFormik } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'
import { handleFormSubmit } from 'utils/api'
import TutorSelect from 'components/atomics/atoms/tutor-select'
import ProvinceSelect from 'components/atomics/atoms/province-select'
import useIpAddress from 'components/hooks/use-ip-address'
import useConfigParams from 'components/hooks/use-config-params'

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

let validationSchema = yup.object().shape({
    first_name: yup.string().required('Il campo è richiesto'),
    last_name: yup.string().required('Il campo è richiesto'),
    email: yup.string().email('Non sembra essere una mail').required('Il campo è richiesto'),
    phone: yup.string().matches(phoneRegExp, 'Non sembra essere un telefono').required('Il campo è richiesto'),
    province: yup.string().required('Il campo è richiesto'),
})

interface ICampaignForm {
    styles?: React.CSSProperties
    termsText: string
    title?: string
    subtitle?: string
    titleStyle?: React.CSSProperties
    buttonStyle?: React.CSSProperties
}

const Form = React.forwardRef<HTMLDivElement, ICampaignForm>((props, ref) => {
    const { styles, termsText, title, subtitle, titleStyle, buttonStyle } = props
    const [loading, setLoading] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const ipAddress = useIpAddress()
    const { pathname } = useLocation()
    const { campaign_id, bearer, placement_id, channel, distributor_id, name, surname, email, phone, province } =
        useConfigParams()

    const getInputList = React.useCallback(() => {
        switch (pathname) {
            case '/ecampus':
                return ['first_name', 'last_name', 'email', 'phone', 'province', 'tutor']
            default:
                return ['first_name', 'last_name', 'email', 'phone', 'province']
        }
    }, [pathname])

    const handleSubmit = React.useCallback(
        (values: {
            first_name: string
            last_name: string
            email: string
            phone: string
            province: string
            tutor: string
            terms: string
        }) => {
            if (campaign_id && ipAddress) {
                setLoading(true)

                handleFormSubmit({
                    token: bearer || undefined,
                    placement_id: placement_id || undefined,
                    campaign_id,
                    distributor_id: distributor_id || undefined,
                    channel_id: channel || undefined,
                    user: {
                        first_name: values.first_name,
                        last_name: values.last_name,
                        mobile_number: values.phone,
                        email: values.email,
                        province: values.province,
                        consenso: values.terms === 'true',
                        ip: ipAddress,
                        ...(values.tutor && { tutor: values.tutor }),
                    },
                })
                    .then(() => {
                        setLoading(false)
                        setSuccess(true)
                        analytics.log({ eventName: 'ADS_CONVERSION_SUCCESS' })
                    })
                    .catch(() => {
                        setLoading(false)
                        analytics.log({ eventName: 'ADS_CONVERSION_FAIL' })
                    })
            }
        },
        [placement_id, campaign_id, bearer, distributor_id, ipAddress, channel],
    )

    const form = useFormik({
        initialValues: {
            first_name: name || '',
            last_name: surname || '',
            email: email || '',
            phone: phone || '',
            province:
                provinces.find(
                    (o: { label: string; value: string }) => o.label.toLowerCase() === province?.toLowerCase(),
                )?.value || '',
            tutor: '',
            terms: 'false',
        },
        onSubmit: handleSubmit,
        validationSchema: validationSchema,
    })

    React.useEffect(() => {
        var token = PubSub.subscribe('FORM_SUBMIT', () => form.handleSubmit())
        return () => {
            PubSub.unsubscribe(token)
        }
    }, [])

    return (
        <FormWrapper style={styles} ref={ref}>
            {title ? <h2 style={{ ...titleStyle, marginBottom: 0 }}>{title}</h2> : null}
            {subtitle ? <h4>{subtitle}</h4> : null}
            {getInputList().includes('first_name') ? (
                <Input
                    label="Nome"
                    value={form.values.first_name}
                    onChange={form.handleChange('first_name')}
                    error={form.touched.first_name ? form.errors.first_name : undefined}
                />
            ) : null}
            {getInputList().includes('last_name') ? (
                <Input
                    label="Cognome"
                    value={form.values.last_name}
                    onChange={form.handleChange('last_name')}
                    error={form.touched.last_name ? form.errors.last_name : undefined}
                />
            ) : null}
            {getInputList().includes('last_name') ? (
                <Input
                    label="Email"
                    value={form.values.email}
                    onChange={form.handleChange('email')}
                    error={form.touched.email ? form.errors.email : undefined}
                />
            ) : null}
            {getInputList().includes('phone') ? (
                <Input
                    label="Telefono"
                    value={form.values.phone}
                    onChange={form.handleChange('phone')}
                    error={form.touched.phone ? form.errors.phone : undefined}
                />
            ) : null}
            {getInputList().includes('province') ? (
                <ProvinceSelect
                    placeholder="Seleziona provincia"
                    currentValue={form.values.province}
                    onChange={(value) => form.handleChange('province')(value?.value || '')}
                    error={form.touched.province ? form.errors.province : undefined}
                />
            ) : null}
            {getInputList().includes('tutor') ? (
                <TutorSelect
                    placeholder="Preferenza tutor"
                    currentValue={form.values.tutor}
                    onChange={(value) => form.handleChange('tutor')(value?.value || '')}
                    error={form.touched.tutor ? form.errors.tutor : undefined}
                />
            ) : null}
            <Checkbox
                label={termsText}
                checked={form.values.terms === 'true'}
                onChange={(value) => form.handleChange('terms')(value ? 'true' : 'false')}
            />
            {!success ? (
                <Button
                    loading={loading}
                    style={buttonStyle || undefined}
                    variant="limed"
                    onClick={() => {
                        analytics.log({ eventName: 'ADS_SUBMIT_FORM' })
                        form.handleSubmit()
                    }}>
                    Invia la richiesta
                </Button>
            ) : (
                <h4 style={{ color: 'green' }}>La richiesta è stata inviata correttamente</h4>
            )}
        </FormWrapper>
    )
})

export default Form
