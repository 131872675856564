import * as React from 'react'
import useTheme from '../../../hooks/use-theme'
import styled from 'styled-components'

const StyledDiv = styled.div`
    margin: 0 auto;
    position: relative;
    padding: 0 2rem !important;
`

const StyledFluidDiv = styled.div`
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
`

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    style?: React.CSSProperties
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
    fluid?: boolean
    type?: 'medium' | 'large' | 'xLarge' | 'xxLarge'
}

const Container = ({ children, fluid = false, type = 'large', ...props }: ContainerProps): JSX.Element => {
    const { sizes } = useTheme()
    if (fluid) {
        return <StyledFluidDiv {...props}>{React.cloneElement(children)}</StyledFluidDiv>
    }
    return (
        <StyledDiv {...props} style={{ maxWidth: `${sizes.breakPoints[type]}px` }}>
            {React.cloneElement(children)}
        </StyledDiv>
    )
}

export default Container
