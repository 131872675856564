import styled from 'styled-components'
import Markdown from 'components/atomics/atoms/markdown'
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`
const StyledCheckbox = styled.div`
    position: relative;
    min-width: 30px;
    min-height: 25px;
    input[type='checkbox'] {
        visibility: hidden;
        &:hover {
            cursor: pointer;
        }
    }

    label {
        background-color: white;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
        height: 25px;
        left: 0;
        position: absolute;
        top: 0;
        width: 25px;
    }

    label:after {
        border: 2px solid white;
        border-top: none;
        border-right: none;
        content: '';
        height: 5px;
        left: 6px;
        opacity: 0;
        position: absolute;
        top: 7px;
        transform: rotate(-45deg);
        width: 11px;
    }

    input[type='checkbox']:checked + label:after {
        opacity: 1;
    }
    input[type='checkbox']:checked + label {
        background-color: black;
        border-color: black;
    }
`

const Error = styled.div`
    margin: 1rem 0;
`

const Label = styled.div`
    margin-left: 1rem;
    display: flex;
    align-items: center;
`

const Checkbox = ({ label, error, onChange, checked }: CheckboxProps) => {
    const handleCheckboxChange = () => {
        if (onChange) {
            onChange(!checked)
        }
    }
    return (
        <div>
            <Wrapper onClick={handleCheckboxChange}>
                <StyledCheckbox>
                    <input type="checkbox" checked={checked} />
                    <label />
                </StyledCheckbox>
                {label ? <Markdown style={{ fontSize: '0.8rem' }}>{label}</Markdown> : null}
            </Wrapper>
            {error ? (
                <Error>
                    <label style={{ color: 'red' }}>{error}</label>
                </Error>
            ) : null}
        </div>
    )
}

export default Checkbox
