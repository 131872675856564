import * as React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import useQuery from 'components/hooks/use-query-parameters'

import DefaultPage from 'pages/default-page'
import GrandiScuole from 'pages/grandi-scuole'
import ECampus from 'pages/e-campus'
import GoStudent from 'pages/gostudent'

import NavBar from 'components/atomics/organisms/navbar'
import { handlePageRender } from 'utils/api'

function App() {
    const query = useQuery()
    const bearer = query.get('bearer')
    // const user_id = query.get('ws_user_id')
    const campaign_id = query.get('ws_campaign_id')
    const placement_id = query.get('ws_placement_id')
    const distributor_id = query.get('ws_distributor_id')
    const channel = query.get('ws_channel_id')

    React.useEffect(() => {
        if (campaign_id) {
            handlePageRender({
                token: bearer || undefined,
                campaign_id,
                placement_id: placement_id || undefined,
                distributor_id: distributor_id || undefined,
                channel_id: channel || undefined,
            })
        }
    }, [placement_id, campaign_id, bearer, distributor_id, channel])

    if (!campaign_id) {
        return <p style={{ color: 'red', fontWeight: '600' }}>Missing campaign id</p>
    }

    return (
        <BrowserRouter>
            <NavBar />
            <Routes>
                <Route path="/" element={<DefaultPage />} />
                <Route path="/grandi-scuole" element={<GrandiScuole />} />
                <Route path="/ecampus" element={<ECampus />} />
                <Route path="/gostudent" element={<GoStudent />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
