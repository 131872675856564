import * as React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'

import { SerializedStyles } from '@emotion/serialize'

interface ILoader {
    size?: number | string
    css?: SerializedStyles
    color?: string
}

const Loader = React.memo(({ size, css, color }: ILoader) => {
    return <SyncLoader css={css} color="#ffffff" loading size={size} />
})

export default Loader
