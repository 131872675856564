import * as React from 'react'
import Form from 'components/atomics/organisms/form'
import Container from 'components/atomics/organisms/responsive-container'
import styled from 'styled-components'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import corsi from './data.json'
import Seo from 'components/atomics/organisms/seo'

const Background = styled.div`
    background: url('https://www2.uniecampus.it/info_westudent/img/background.jpg') right top no-repeat;
    background-size: cover;
    background-size: contain;
    min-height: calc(100vh - 4rem);
    padding-top: 12rem;
    padding-bottom: 2rem;
`

const StyledAccordion = styled(Accordion)`
    display: flex;
    width: 100%;
    > div {
        width: calc(100% * 1 / 5);
        &:hover {
            cursor: pointer;
        }
    }
    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        > div {
            width: 100%;
        }
    }
`

const InnerContainer = styled.div`
    padding: 40px 40px 20px 40px;
    @media only screen and (max-width: 1200px) {
        padding: 20px 20px 10px 20px;
    }
`

const ContentWrapper = styled.div`
    background-color: rgba(241, 243, 240, 0.88);
    max-width: 850px;
    font-family: 'Montserrat', sans-serif;
    h1 {
        font-size: 3.2rem;
        color: rgba(0, 82, 135, 1);
        font-weight: 400;
        margin: 0;
        strong {
            font-weight: 900;
        }
    }
    h3 {
        color: rgba(169, 57, 6, 1);
        font-weight: 800;
        font-size: 1.5rem;
        margin: 0;
    }
    h4 {
        color: rgba(0, 82, 135, 1);
        font-size: 1.2rem;
        font-weight: 600;
        margin: 0;
    }
    p {
        font-size: 1.09rem;
        strong {
            font-weight: 600;
        }
    }
`

const TextButton = styled.div`
    color: blue;
    text-decoration: underline;
    font-weight: 500;
    &:hover {
        cursor: pointer;
    }
`

const TitleUnder = styled.span`
    display: block;
    margin: 3px 0 0 0;
    border-bottom: 1px solid rgb(169, 57, 6);
    height: 3px;
    width: 200px;
    > div {
        background: rgb(169, 57, 6);
        height: 3px;
        width: 50%;
    }
`

const GradientDivider = styled.div`
    border: 0;
    height: 3px;
    background-image: linear-gradient(to right, rgba(169, 57, 6, 1), rgba(0, 82, 135, 1), rgba(169, 57, 6, 1));
    margin: 2rem 0;
`

const ECampus = () => {
    const formRef = React.createRef<HTMLDivElement>()
    return (
        <div style={{ backgroundColor: '#BBBCC1', paddingTop: '4rem' }}>
            <Seo title="ECampus" description="Scopri il corso di laurea adatto a te" />
            <Container type="xxLarge">
                <Background>
                    <ContentWrapper>
                        <InnerContainer>
                            <h1>
                                <strong>INDECISO</strong> SUL CORSO DI LAUREA?
                            </h1>
                            <h3>Ti aiutiamo a scoprire quello perfetto per te</h3>
                            <GradientDivider />
                            <h4 style={{ marginTop: '2rem' }}>
                                eCampus è l’università su misura per te{' '}
                                <TitleUnder>
                                    <div />
                                </TitleUnder>
                            </h4>
                            <p>
                                <strong>L’Università eCampus è uno dei principali Atenei online in Italia</strong>, con{' '}
                                <strong>60 percorsi di studio</strong>
                                tradizionali e innovativi orientati al mondo del lavoro e in continua crescita per
                                essere sempre al passo con i tempi. eCampus è organizzata secondo un{' '}
                                <strong>modello didattico esclusivo pensato a partire dallo studente</strong>.
                            </p>
                            <h4 style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                                <strong>Informati subito!</strong>
                            </h4>
                            <Form
                                ref={formRef}
                                buttonStyle={{ background: 'rgba(0, 82, 135, 1)' }}
                                styles={{ border: '1px solid rgb(169,57,6)', padding: '1rem' }}
                                termsText="In relazione all'informativa ([Privacy Policy](https://www2.uniecampus.it/info_uniecampus_westudent.asp?utm_source=WeStudents&utm_medium=Adv&utm_term=Ecampus.WeStudent&utm_content=2021-12.eCampus&utm_campaign=2021-12.eCampus&idm=120275), art. 13 e art. 14 GDPR 2016/679), che dichiaro di aver letto,   ACCONSENTO  al trattamento dei miei dati personali."
                            />
                            <h4 style={{ marginTop: '2rem' }}>
                                In eCampus tutto è pensato per farti studiare meglio
                                <TitleUnder>
                                    <div />
                                </TitleUnder>
                            </h4>
                            <ul style={{ paddingLeft: '17px' }}>
                                <li>
                                    <p>
                                        <strong>Le lezioni sono sempre disponibili online</strong> e sono erogate
                                        secondo un <strong>calendario personalizzato</strong>
                                        costruito in base al tempo che puoi dedicare allo studio;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Hai un <strong>tutor online</strong> a tua disposizione per tutto il percorso
                                        universitario;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Se vuoi, hai l'assistenza di un <strong>tutor personale</strong>, disponibile
                                        online e in presenza, che ti guida nello studio e chiarisce i tuoi dubbi;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>
                                            Sostieni gli esami e partecipi alle eventuali attività di laboratorio
                                        </strong>{' '}
                                        previste dal tuo corso di laurea in una delle <strong>numerose sedi</strong>{' '}
                                        universitarie distribuite in tutta Italia;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Le <strong>sessioni d'esame annuali</strong> sono pianificate secondo un
                                        calendario stabilito così puoi organizzarti al meglio;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Puoi partecipare agli <strong>stage</strong> e ai <strong>tirocini</strong>{' '}
                                        proposti dall’<strong>Ufficio Placement</strong> per completare sul campo la tua
                                        formazione e agevolare il tuo <strong>ingresso nel mondo del lavoro</strong>.
                                    </p>
                                </li>
                            </ul>
                            <h4 style={{ marginTop: '2rem', color: 'rgb(169,57,6)' }}>
                                Vuoi saperne di più?
                                <TitleUnder>
                                    <div />
                                </TitleUnder>
                            </h4>
                            <p>
                                Se sei interessato a ricevere maggiori informazioni sull’Università eCampus
                                <TextButton
                                    onClick={() => {
                                        formRef.current?.scrollIntoView()
                                        PubSub.publish('FORM_SUBMIT')
                                    }}>
                                    compila il form.
                                </TextButton>
                            </p>
                            <h4 style={{ marginTop: '2rem' }}>
                                Intanto dai un’occhiata ai nostri corsi di laurea
                                <TitleUnder>
                                    <div />
                                </TitleUnder>
                            </h4>
                            <p>Siamo abbastanza sicuri che troverai quello perfetto per te!</p>
                        </InnerContainer>
                        <StyledAccordion allowMultipleExpanded allowZeroExpanded>
                            {corsi.map((item) => (
                                <AccordionItem key={item.topic} style={{ textAlign: 'left' }}>
                                    <AccordionItemHeading style={{ background: item.color, padding: '0.5rem 1rem' }}>
                                        <AccordionItemButton style={{ textAlign: 'center' }}>
                                            <h5 style={{ color: 'white', fontSize: '1rem', margin: 0 }}>
                                                {item.topic}
                                            </h5>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel style={{ margin: '0.5rem 0', padding: '0 0.5rem' }}>
                                        <h5 style={{ color: 'black', fontSize: '1rem', margin: 0 }}>Corsi triennale</h5>
                                        {item.bachelors.map((item, index) => {
                                            const category = item.category
                                            return (
                                                <div key={`corsi-triennale${index}`}>
                                                    {item.data
                                                        ? item.data.map((item) => (
                                                              <div key={`corsi-triennale-${category}-${item}`}>
                                                                  <p
                                                                      style={{
                                                                          fontWeight: '500',
                                                                          marginBottom: 0,
                                                                          fontSize: '0.85rem',
                                                                          marginTop: '0.5rem',
                                                                      }}>
                                                                      {category}
                                                                  </p>
                                                                  <p
                                                                      style={{
                                                                          fontStyle: 'italic',
                                                                          margin: 0,
                                                                          fontSize: '0.85rem',
                                                                      }}>
                                                                      {item}
                                                                  </p>
                                                              </div>
                                                          ))
                                                        : null}
                                                </div>
                                            )
                                        })}
                                        <h5 style={{ color: 'black', fontSize: '1rem', margin: 0 }}>
                                            Corsi magistrale
                                        </h5>
                                        {item.bachelors.map((item, index) => {
                                            const category = item.category
                                            return (
                                                <div key={`corsi-magistrale${index}`}>
                                                    {item.data
                                                        ? item.data.map((item) => (
                                                              <div key={`corsi-magistrale-${category}-${item}`}>
                                                                  <p
                                                                      style={{
                                                                          fontWeight: '500',
                                                                          marginBottom: 0,
                                                                          marginTop: '0.5rem',
                                                                          fontSize: '0.85rem',
                                                                      }}>
                                                                      {category}
                                                                  </p>
                                                                  <p
                                                                      style={{
                                                                          fontStyle: 'italic',
                                                                          margin: 0,
                                                                          fontSize: '0.85rem',
                                                                      }}>
                                                                      {item}
                                                                  </p>
                                                              </div>
                                                          ))
                                                        : null}
                                                </div>
                                            )
                                        })}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </StyledAccordion>
                        <div style={{ display: 'flex' }}>
                            <img
                                src="https://www2.uniecampus.it/landing_facolta/img/logo-ecampus.png"
                                alt="E-campus"
                                style={{ height: '3rem', margin: '2rem auto' }}
                            />
                        </div>
                        <InnerContainer>
                            <p style={{ color: 'rgba(0, 82, 135, 1)', textAlign: 'center', fontSize: '0.8rem' }}>
                                © 2007 Università degli Studi eCampus - Via Isimbardi 10, 22060 Novedrate (CO) -
                                C.F.90027520130
                            </p>
                        </InnerContainer>
                    </ContentWrapper>
                </Background>
            </Container>
        </div>
    )
}

export default ECampus
