import axios from 'axios'
import getEnvs from 'utils/envs'

const { API_URL } = getEnvs()

export const handlePageRender = (data: PageViewLogProps) => {
    const { token, campaign_id, placement_id, distributor_id, channel_id } = data
    const response = axios
        .post(
            `${API_URL}ads/events`,
            {
                category: 'ADS',
                action: 'VIEW',
                campaign: campaign_id,
                placement: placement_id,
                ...(distributor_id && { distributor: distributor_id }),
                ...(channel_id && { channel: channel_id }),
            },
            { headers: { ...(token && { Authorization: `Bearer ${token}` }) } },
        )
        .catch(function (error) {
            console.log('ERROR LOGGING PAGE VIEW ACTION')
            throw new Error(error)
        })

    return response
}

export const handleFormSubmit = (data: FormSubmitLogProps) => {
    const { token, campaign_id, placement_id, user, distributor_id, channel_id } = data
    const response = axios
        .post(
            `${API_URL}ads/events`,
            {
                category: 'ADS',
                action: 'CONVERSION',
                campaign: campaign_id,
                placement: placement_id,
                ...(distributor_id && { distributor: distributor_id }),
                ...(channel_id && { channel: channel_id }),
                data: user,
            },
            { headers: { ...(token && { Authorization: `Bearer ${token}` }) } },
        )
        .catch(function (error) {
            console.log('ERROR LOGGING SUBMIT ACTION')
            throw new Error(error)
        })
    return response
}
