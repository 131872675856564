import { Helmet } from 'react-helmet'

interface ISeo {
    title: string
    description: string
}

const Seo = ({ title, description }: ISeo) => {
    return (
        <Helmet encodeSpecialCharacters={true}>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    )
}

export default Seo
