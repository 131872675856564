const DefaultPage = () => {
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <p style={{ color: 'red', fontWeight: '700' }}>Missing campaign</p>
        </div>
    )
}

export default DefaultPage
