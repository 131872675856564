/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import styled from 'styled-components'
import { forwardRef } from 'react'
import { noop } from 'lodash'

import { Field } from 'formik'

interface BaseInputProps extends InputProps {
    onIconClick?: () => void
    inForm?: boolean
}

export const Label = styled.div<{ active?: boolean }>`
    position: absolute;
    z-index: 10;
    top: ${(props) => (props.active ? '20%' : '50%')};
    left: 1rem;
    transform: translate(0, -50%);
    transition: 0.3s;
    display: block;
    color: grey;
    > * {
        font-weight: 500 !important;
        font-size: ${(props) => (props.active ? 0.8 : 1)}rem !important;
    }
`

export const BaseInputWrapper = styled.div`
    box-sizing: border-box;
    border-radius: 1rem;
    overflow: hidden;
    height: 3.1rem;
    outline: none;
    background: ${(props) => props.theme.colors.white};
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    font-family: inherit;

    button {
        position: absolute;
        right: 0;
        top: 0;
        margin-left: 0;
        border: none !important;
        background: transparent;
        height: 100%;
        z-index: 5;
        svg {
            height: 100%;
        }
        &:hover {
            cursor: pointer;
        }
    }

    input:disabled {
        background: #d0d0d0;
    }

    input {
        z-index: 3;
        padding-right: 1rem;
        height: 100%;
        width: 100%;
        padding-left: 1rem;
        padding-top: 0.7rem;
        outline: none;
        border: none;
        box-sizing: border-box;
        flex: 1;
        font-size: 1rem;
        ::placeholder {
            color: ${(props) => props.theme.colors.grey};
        }
    }
    color: black !important;
    .input-euro {
        position: relative;
    }
    .input-euro.left input {
        padding-left: 2rem;
    }
    .input-euro:before {
        position: absolute;
        top: 0;
        content: '€';
    }
    .input-euro.left:before {
        left: 1rem;
    }
`

const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
    ({ inForm = false, rightIcon, onIconClick, error, label, onFocus = noop, onBlur = noop, ...props }, ref) => {
        const [labelActive, setLabelActive] = React.useState<boolean>()
        const [innerValue, setInnerValue] = React.useState<string>()
        const inputRef = React.useRef<HTMLInputElement>(null)

        // @ts-ignore:
        React.useImperativeHandle(ref, () => ({
            focus: (): void => inputRef.current?.focus(),
            blur: (): void => inputRef.current?.blur(),
        }))

        const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
            if (!props.disabled) {
                setLabelActive(true)
            }
            onFocus(event)
        }

        const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
            if (!props.value && !innerValue && !inputRef.current?.value) {
                setLabelActive(false)
            }
            onBlur(event)
        }

        const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
            if (props.onChange) {
                props.onChange(e)
            }
            setInnerValue(e.target.value)
        }

        React.useEffect(() => {
            if (inputRef.current?.value) {
                setLabelActive(true)
            }
        }, [inputRef.current])

        return (
            <BaseInputWrapper>
                {label ? (
                    <Label active={labelActive} onClick={() => inputRef.current?.focus()}>
                        <h5 style={{ color: error ? 'red' : 'grey' }}>{label}</h5>
                    </Label>
                ) : null}
                {inForm ? (
                    <Field
                        {...props}
                        autoComplete="off"
                        ref={inputRef}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={handleOnChange}
                    />
                ) : (
                    <>
                        {props.isCurrency ? (
                            <span className="input-euro left">
                                <input
                                    {...props}
                                    autoComplete="off"
                                    ref={inputRef}
                                    onFocus={onInputFocus}
                                    onBlur={onInputBlur}
                                    onChange={handleOnChange}
                                />
                            </span>
                        ) : (
                            <input
                                {...props}
                                autoComplete="off"
                                ref={inputRef}
                                onFocus={onInputFocus}
                                onBlur={onInputBlur}
                                onChange={handleOnChange}
                            />
                        )}
                    </>
                )}
            </BaseInputWrapper>
        )
    },
)

export default BaseInput
