const colors = {
    background: {
        '100': '#1A1A1A',
        '200': '#212121',
        '300': '#414042',
        '400': '#616161',
        '500': '#898989',
        '600': '#ABABAB',
        '700': '#D1D1D1',
        '800': '#EEEEEE',
        '900': '#F6F6F6',
    },
    primary: {
        '100': '#FFE0CE',
        '500': '#FFC29C',
        '900': '#FF9D64',
    },
    success: {
        '100': '#9CDABD',
        '500': '#73C294',
        '900': '#00AC47',
    },
    info: {
        '100': '#BBD8FF',
        '500': '#8ABCFF',
        '900': '#327FE8',
    },
    warning: {
        '100': '#FFF0C9',
        '500': '#FFE396',
        '900': '#FFBA00',
    },
    danger: {
        '100': '#FBA0A3',
        '500': '#E86F73',
        '900': '#C32328',
    },
    gradients: {
        orange: {
            start: '#C32328',
            end: '#E88036',
        },
    },
    base: {
        white: '#FFFFFF',
        black: '#000000',
    },
    text: {
        normal: '#414042',
        light: '#898889',
    },
    iconDefaultColor: '#414042',
    erColors: {
        ARGO: '#A2962A',
        AXIOS: '#00A793',
        CLASSEVIVA: '#A01952',
        MASTERCOM: '#D894F8',
    },
}

const typography = {
    h1: {
        size: '2rem',
        weight: '700',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    h2: {
        size: '2rem',
        weight: '700',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    h3: {
        size: '2rem',
        weight: '700',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    h4: {
        size: '2rem',
        weight: '700',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    h5: {
        size: '2rem',
        weight: '400',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    h6: {
        size: '2rem',
        weight: '400',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    p: {
        size: '0.7rem',
        weight: '400',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    p2: {
        size: '0.5rem',
        weight: '400',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    p3: {
        size: '0.5rem',
        weight: '400',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    label: {
        size: '0.5rem',
        weight: '700',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
    label2: {
        size: '0.5rem',
        weight: '400',
        color: colors.text.normal,
        family: 'TitilliumWeb',
        italic: false,
    },
}

const sizes = {
    spacings: {
        xs: 4,
        s: 10,
        m: 20,
        l: 24,
        xl: 28,
        xxl: 32,
    },
    breakPoints: {
        small: 576,
        medium: 768,
        large: 1024,
        xLarge: 1280,
        xxLarge: 1600,
    },
    tabBarHeight: 50,
    navBarSpaceHeight: 70,
    headerHeight: 60,
    borderRadius: {
        extraBig: 30,
        big: 20,
        normal: 10,
        small: 8,
        extraSmall: 5,
    },
}

const isDark = false

const theme: Theme = {
    colors,
    typography,
    sizes,
    isDark,
}

export default theme
