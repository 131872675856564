/* eslint-disable prettier/prettier */
import './index.css'
import * as React from 'react'
import { handleFormSubmit } from '../../utils/api'
import styled from 'styled-components'
import useConfigParams from 'components/hooks/use-config-params'
import useIpAddress from 'components/hooks/use-ip-address'
import Seo from 'components/atomics/organisms/seo'
import analytics from 'utils/analytics'

const Body = styled.div`
    padding: 0 2rem;
    margin: 4.5rem 0;
    h2 {
        margin-top: 0 !important;
    }
    font-family: 'Montserrat' !important;
`




const FeaturesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);

    img {
        width: 80px;
        margin: auto;
    }

    h4 {
        margin-bottom: 0.3rem;
    }

    p {
        padding-right: 0.5rem;
        font-size: 70%;
    }
`


const GoStudent = () => {
    const ipAddress = useIpAddress()
    const { campaign_id, bearer, placement_id, channel, distributor_id, title } = useConfigParams()
    const features = [
        {
            title: 'Face to face',
            description:
                'Contatto personale con il proprio tutor attraverso una moderna tecnologia di apprendimento e insegnamento',
            img: 'https://partner.gostudent.org/hubfs/LPs_2019/images/icons/icn_see_your_tutor.svg',
        },
        {
            title: 'Lavagna digitale',
            description: 'Scrivi, disegna e fai schizzi sulla nostra lavagna digitale',
            img: 'https://partner.gostudent.org/hubfs/LPs_2019/images/icons/icn_draw_sketches.svg',
        },
        {
            title: 'Registra le lezioni',
            description: 'Rivedi le lezioni precedenti ed esercitati più volte',
            img: 'https://partner.gostudent.org/hubfs/LPs_2019/images/icons/icn_send_messages.svg',
        },
        {
            title: 'Condividi i file',
            description: "Condividi compiti, esercizi e materiali d'esame per stare al passo con tutto",
            img: 'https://partner.gostudent.org/hubfs/LPs_2019/images/icons/icn_share_screen.svg',
        },
    ]

     React.useEffect(() => {
         let timeout = setTimeout(() => {
             const element = document.getElementById('hsForm_0c168c08-3e88-4a78-8722-3a0a4dea8b7b')
             if (element) {
                element.addEventListener('submit', function (e) {
                        const formData = new FormData(e.target as any)
                        const formProps = Object.fromEntries(formData)
                        const name = formProps.firstname
                        const lastname = formProps.lastname
                        const phone = formProps.phone
                        const email = formProps.email
                        const subject = formProps.trial_session_subject
                        if (campaign_id && ipAddress && name && lastname && email && phone && subject) {
                            handleFormSubmit({
                                token: bearer || undefined,
                                placement_id: placement_id || undefined,
                                campaign_id,
                                distributor_id: distributor_id || undefined,
                                channel_id: channel || undefined,
                                user: {
                                    first_name: name as string,
                                    last_name: lastname as string,
                                    mobile_number: phone as string,
                                    email: email as string,
                                    province: '',
                                    consenso: false,
                                    ip: ipAddress,
                                },
                            }).then(() => {
                                analytics.log({ eventName: 'ADS_CONVERSION_SUCCESS' })
                            })
                            .catch(() => {
                                analytics.log({ eventName: 'ADS_CONVERSION_FAIL' })
                            })
                         }
                     })
             }
         }, 1500)

         return () => clearTimeout(timeout)
    }, [placement_id, campaign_id, bearer, ipAddress, distributor_id, channel])

    React.useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: '4429452',
                    formId: '0c168c08-3e88-4a78-8722-3a0a4dea8b7b',
                    target: '#goStudentHubspotForm',
                    /* onFormSubmit: (e: any) => {
                        console.log('submit event', e)
                    }  */
                })
            }
        });
    }, []);


    return (
        <div>
            <Seo title="GoStudent" description="L'alleato perfetto per i tuoi compiti" />
            <Body>
                <h2>{title ? title : `GoStudent - l'alleato perfetto per i tuoi compiti`}</h2>
                <p>
                    🔥 Che tu voglia recuperare una materia o semplicemente migliorare, GoStudent è la scelta migliore 
                </p>
                <p>
                    ✅ Abbiamo già aiutato centinaia di studenti a <strong>superare e difficoltà</strong> e iniziare quest'anno con il piede giusto 
                </p>
                <p>
                    🤯 <strong>Non ci credi?</strong> Prenota una lezione 1:1 oggi (è gratis) e rimarrai sbalordito 
                </p>
                <h2> ⬇️ Richiedi una lezione gratuita ⬇️</h2>
                <div id="goStudentHubspotForm" />
                <FeaturesWrapper>
                    {features.map((item) => (
                        <div key={item.title}>
                            <img src={item.img} alt="GoStudent" />
                            <h4>{item.title}</h4>
                            <p>{item.description}</p>
                        </div>
                    ))}
                </FeaturesWrapper>
            </Body>
        </div>
        
    )
}

export default GoStudent
