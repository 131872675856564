import * as React from 'react'
import useQuery from 'components/hooks/use-query-parameters'
import styled from 'styled-components'
import Container from '../responsive-container'
import { useLocation } from 'react-router-dom'

const StyledContainer = styled(Container)`
    width: 100%;
    > div {
        display: flex;
        justify-content: space-between;
    }
`

const NavBarWrapper = styled.div`
    background: white;
    width: 100%;
    height: 4rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
`

const NavBar = () => {
    const { pathname } = useLocation()
    const query = useQuery()
    const distributor_id = query.get('ws_distributor_id')

    const getClientLogo = React.useCallback(() => {
        switch (pathname) {
            case '/grandi-scuole':
                return 'https://www.grandiscuole.it/img/logo-2020.png'
            case '/ecampus':
                return 'https://www2.uniecampus.it/img/logo-new.png'
            case '/gostudent':
                return 'https://partner.gostudent.org/hubfs/LPs_2019/images/logos/gs-logo-black.svg'
            default:
                return ''
        }
    }, [pathname])

    const getDistributorLogo = React.useCallback(() => {
        switch (distributor_id) {
            case '62260a794645e406dc7e75bb': //masterapps
                return '/logo_masterapps.png'
            case '621e0626d33ec8ad4f198153': //mangaup
                return '/logo_mangaup.png'
            default:
                return 'https://partner.gostudent.org/hubfs/we%20students%20logo.svg'
        }
    }, [distributor_id])
    return (
        <NavBarWrapper>
            <StyledContainer type="xxLarge">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {getClientLogo() ? <img style={{ height: '3rem' }} src={getClientLogo()} alt="WeStudents" /> : null}
                    <img style={{ height: '2rem' }} src={getDistributorLogo()} alt="WeStudents" />
                </div>
            </StyledContainer>
        </NavBarWrapper>
    )
}

export default NavBar
