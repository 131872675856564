import Select, { Props } from 'react-select'
import styled from 'styled-components'

const Error = styled.div`
    text-align: left;
    margin-top: 0.4rem;
`

interface ProvinceSelectProps extends Props<{ value: string; label: string }, false> {
    error?: string
    currentValue: string
}

const ProvinceSelect = (props: ProvinceSelectProps) => {
    const customStyles = {
        input: (provided: any) => ({
            ...provided,
            height: '3.1rem',
            margin: 0,
            border: 'none',
            fontSize: '0.9rem',
            color: 'black',
        }),
        container: (provided: any) => ({
            ...provided,
            height: '3.1rem',
            border: 'none',
        }),
        control: (provided: any) => ({
            ...provided,
            height: '3.1rem',
            borderRadius: '0.9rem',
            border: 'none',
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            height: '3.1rem',
            padding: '0 1rem',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            fontSize: '1rem',
        }),
        menu: (provided: any) => ({
            ...provided,
            marginTop: '0',
            zIndex: '1000',
            display: 'block',
        }),
    }

    const toutorTypes = [
        { label: 'online', value: 'ONLINE' },
        { label: 'personale', value: 'PERSONAL' },
    ]

    return (
        <div>
            <Select
                styles={customStyles}
                defaultValue={toutorTypes.find((o: { label: string; value: string }) => o.value === props.currentValue)}
                {...props}
                options={toutorTypes}
            />
            {props.error ? (
                <Error>
                    <span style={{ color: 'red' }}>{props.error}</span>
                </Error>
            ) : null}
        </div>
    )
}

export default ProvinceSelect
