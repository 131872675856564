import publicIp from 'public-ip'
import * as React from 'react'

const useIpAddress = () => {
    const [ipAddress, setIpAddress] = React.useState<string | undefined>(undefined)
    const ip_address = React.useCallback(async () => {
        const ipResponse = await publicIp.v4()
        setIpAddress(ipResponse)
    }, [])

    React.useEffect(() => {
        ip_address()
    }, [])

    return ipAddress
}

export default useIpAddress
