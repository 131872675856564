import useTheme from 'components/hooks/use-theme'
import _ from 'lodash'
import { default as ReactMarkdown } from 'react-markdown'
import { Link } from 'react-router-dom'
import innerNavigationLinkHelper from 'utils/navigation/inner-navigation-link-helper'
import isInnerLink from 'utils/navigation/is-inner-link'

const Markdown = ({ children, style }: { children: string; style?: React.CSSProperties }) => {
    const theme = useTheme()
    const getStyle = (type: keyof Typography) => {
        const typographyItem = theme.typography[type]
        const mdStyle = {
            fontSize: typographyItem.size,
            color: typographyItem.color,
        }
        return {
            mdStyle,
            ...style,
        }
    }

    return (
        <ReactMarkdown
            components={{
                h1: ({ children, ...props }) => (
                    <h1 style={getStyle('h1')} {...props}>
                        {children}
                    </h1>
                ),
                h2: ({ children, ...props }) => (
                    <h2 style={getStyle('h2')} {...props}>
                        {children}
                    </h2>
                ),
                h3: ({ children, ...props }) => (
                    <h3 style={getStyle('h3')} {...props}>
                        {children}
                    </h3>
                ),
                h4: ({ children, ...props }) => (
                    <h4 style={getStyle('h4')} {...props}>
                        {children}
                    </h4>
                ),
                h5: ({ children, ...props }) => (
                    <h5 style={getStyle('h5')} {...props}>
                        {children}
                    </h5>
                ),
                h6: ({ children, ...props }) => (
                    <h5 style={getStyle('h6')} {...props}>
                        {children}
                    </h5>
                ),
                p: ({ children, ...props }) => (
                    <p style={getStyle('p')} {...props}>
                        {children}
                    </p>
                ),
                a: ({ children, ...props }) => {
                    const { properties } = props.node
                    if (isInnerLink(properties?.href as string)) {
                        return (
                            <Link style={style} to={innerNavigationLinkHelper(properties?.href as string)}>
                                {children}
                            </Link>
                        )
                    }
                    return (
                        <a {...props} style={style} rel="noopener noreferrer" target="_blank">
                            {children}
                        </a>
                    )
                },
            }}>
            {children}
        </ReactMarkdown>
    )
}

export default Markdown
