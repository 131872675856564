import useQuery from '../use-query-parameters'

const useConfigParams = () => {
    const query = useQuery()
    const campaign_id = query.get('ws_campaign_id')
    const channel = query.get('ws_channel_id')
    const bearer = query.get('bearer')
    const placement_id = query.get('ws_placement_id')
    const distributor_id = query.get('ws_distributor_id')
    const name = query.get('ws_user_first_name')
    const surname = query.get('ws_user_last_name')
    const email = query.get('ws_user_email')
    const phone = query.get('ws_mobile_number')
    const province = query.get('ws_plesso_province')
    const title = query.get('ws_title')
    return {
        campaign_id,
        channel,
        bearer,
        placement_id,
        distributor_id,
        name,
        surname,
        email,
        phone,
        province,
        title,
    }
}

export default useConfigParams
