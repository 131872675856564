import * as React from 'react'
import styled from 'styled-components'
import BaseInput from './components/base-input'

const Error = styled.div`
    text-align: left;
    margin-top: 0.4rem;
`

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ error, type = 'text', ...props }, ref): JSX.Element => {
    const renderInput = () => {
        switch (type) {
            default:
                return <BaseInput type={type} ref={ref} error={error} {...props} />
        }
    }

    return (
        <div>
            {renderInput()}
            {error ? (
                <Error>
                    <h6 style={{ color: 'red' }}>{error}</h6>
                </Error>
            ) : null}
        </div>
    )
})

export default Input
