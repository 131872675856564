import React from 'react'
import styled, { ThemedStyledProps } from 'styled-components'
import { css } from '@emotion/react'
import Loader from '../loader'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactElement | string
    size?: 'big' | 'medium' | 'small'
    variant?: 'solid' | 'inset' | 'limed'
    wrapperStyle?: React.CSSProperties
    onClick?: () => void
    loading?: boolean
}

const getHeight = ({ size }: IButton): string => {
    if (size === 'big') return '2.5em'
    return '2.1em'
}

const getFontSize = ({ size }: IButton): string => {
    if (size === 'big') return '2em'
    return '1.2em'
}

const getBackgroundColor = ({ size, variant, ...props }: IButton): string => {
    const { theme } = props as ThemedStyledProps<any, any>
    if (variant === 'inset') return 'transparent'
    if (variant === 'limed') return theme.colors.primary[900]
    return theme.colors.white
}

const getBorder = ({ variant, ...props }: IButton): string => {
    const { theme } = props as ThemedStyledProps<any, any>
    if (variant === 'inset') return `2px solid ${theme.colors.white}`
    return 'none'
}

const getColor = ({ variant, ...props }: IButton): string => {
    const { theme } = props as ThemedStyledProps<any, any>
    if (variant === 'inset' || variant === 'limed') return theme.colors.base.white
    return theme.colors.black
}

const getBorderRadius = ({ size }: IButton): string => {
    if (size === 'big') return '1.5em'
    return '0.75em'
}

const CustomButton = styled.button((props) => {
    return {
        position: 'relative',
        height: getHeight(props as IButton),
        padding: '0.3em 1em',
        borderRadius: getBorderRadius(props as IButton),
        backgroundColor: getBackgroundColor(props as IButton),
        border: getBorder(props as IButton),
        fontWeight: 600,
        width: '100%',
        fontSize: getFontSize(props as IButton),
        color: getColor(props as IButton),
        justifyContent: 'center',
        overflow: 'hidden',
        opacity: (props as IButton).loading ? 0.4 : 1,
        ...(props as IButton).wrapperStyle,

        '&:hover': {
            opacity: (props as IButton).loading ? 0.4 : 0.8,
            cursor: (props as IButton).loading ? 'not-allowed' : 'pointer',
        },
    }
})

const override = css`
    position: absolute;
    right: 2rem;
`

const Button = React.memo(({ children, onClick, ...others }: IButton) => {
    const handleClick = React.useCallback(() => {
        if (onClick && others.loading !== true && others.disabled !== true) {
            onClick()
        }
    }, [onClick, others.disabled, others.loading])

    return (
        <CustomButton onClick={handleClick} {...others}>
            {others.loading ? <Loader css={override} size=".8rem" /> : null}
            {children}
        </CustomButton>
    )
})

export default Button
