import * as React from 'react'
import styled from 'styled-components'

import Seo from 'components/atomics/organisms/seo'
import Form from 'components/atomics/organisms/form'
import Container from 'components/atomics/organisms/responsive-container'
import useWindowSize from 'components/hooks/use-window-size'

const TopSection = styled.div`
    width: 100%;
    background: rgba(255, 111, 20, 1);
    box-shadow: 0px 10px 10px -5px rgba(77, 77, 77, 0.164);
`

const StyledContainer = styled(Container)`
    display: flex;
`

const Intro = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    @media only screen and (min-width: 1200px) {
        text-align: left;
        flex-direction: row;
        > div:last-child {
            width: calc(100% * 1 / 4);
        }
    }
`

const CtaWrapper = styled.div`
    display: flex;
    justify-content: center;
    > div {
        font-family: 'Bungee', cursive;
        h2 {
            font-size: 2.5rem;
            margin: 0;
            color: white;
        }
        h1 {
            color: rgba(254, 237, 1, 1);
            font-size: 3.1rem;
            margin: 0;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem 0;
    }
    @media only screen and (min-width: 1200px) {
        height: 100%;
        background-size: cover;
        justify-content: flex-end;
        width: calc(100% * 3 / 4);
        height: 100%;
        background: url('https://www.grandiscuole.it/info_westudent/img/raga-ok.jpg') left bottom no-repeat;
        > div {
            padding-right: 2rem;
        }
    }
`

const Feature = styled.div`
    border-left: rgba(255, 111, 20, 1) dotted 3px;
    padding: 0 2rem;
    h3 {
        margin-top: 0;
        color: rgba(255, 111, 20, 1);
    }
`

const Footer = styled.div`
    text-align: center;
    height: 8rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background: rgba(255, 111, 20, 1);
    padding: 0 3rem;
`

const TextButton = styled.div`
    color: blue;
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
`

const ImageMobile = styled.div`
    display: block;
    height: 400px;
    width: 100%;
    position: relative;
    > img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: contain;
        object-position: bottom;
    }
    background: url('https://www.grandiscuole.it/info_westudent/img/raga-ok.jpg') center no-repeat;
    @media only screen and (min-width: 1200px) {
        display: none;
    }
`

const Features = styled.div`
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    @media only screen and (min-width: 1200px) {
        flex-direction: row;
    }
`

const Divider = styled.div`
    border-bottom: 2px solid grey;
    width: 100%;
`

const GridFeatures = styled.div`
    display: grid;
    width: 100%;
    padding: 3rem 0;
    font-family: Montserrat, sans-serif;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
    @media only screen and (min-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
`

const GrandiScuole = () => {
    const formRef = React.createRef<HTMLDivElement>()
    const { width } = useWindowSize()
    const features = [
        {
            label: 'Percorso di recupero personalizzato',
            img: 'https://www.grandiscuole.it/info_westudent/img/ico-percorso-recupero.jpg',
        },
        {
            label: 'Metodo di studio efficace',
            img: 'https://www.grandiscuole.it/info_westudent/img/ico-metodo-efficace.jpg',
        },
        {
            label: 'Tutor specializzato a tua disposizione',
            img: 'https://www.grandiscuole.it/info_westudent/img/ico-tutor.jpg',
        },
        {
            label: 'Lezioni mirate individuali o in miniclassi',
            img: 'https://www.grandiscuole.it/info_westudent/img/ico-individuali-miniclassi.jpg',
        },
        {
            label: 'Studio in presenza e online',
            img: 'https://www.grandiscuole.it/info_westudent/img/ico-presenza-online.jpg',
        },
    ]
    return (
        <div style={{ marginTop: '4rem', fontFamily: 'Montserrat, sans-serif', position: 'relative' }}>
            <Seo title="Grandi Scuole" description="Grandi scuole ads landing" />
            <ImageMobile style={{ background: 'rgba(255, 111, 20, 1)', width: '100%' }}>
                <img src="https://www.grandiscuole.it/info_westudent/img/raga-ok.jpg" alt="Grandi scuole" />
            </ImageMobile>
            <TopSection>
                <StyledContainer type="xxLarge" fluid={width < 1200}>
                    <Intro>
                        <CtaWrapper>
                            <div>
                                <h2>TRASFORMA</h2>
                                <h2>LE INSUFFICIENZE</h2>
                                <h1>IN OTTIMI VOTI</h1>
                            </div>
                        </CtaWrapper>
                        <div>
                            <Form
                                ref={formRef}
                                titleStyle={{
                                    textAlign: 'center',
                                    fontFamily: 'Montserrat, sans-serif',
                                    color: 'rgba(255, 111, 20, 1)',
                                }}
                                title="INFORMATI SUBITO"
                                termsText="In relazione all'informativa ([Privacy Policy](https://www.grandiscuole.it/info_Gs_WeStudent.asp?utm_source=WeStudents&utm_medium=Adv&utm_term=Gs.WeStudent&utm_content=2021-12.Gs&utm_campaign=2021-12.Gs&idm=120276), art. 13 e art. 14 GDPR 2016/679), che dichiaro di aver letto,  ACCONSENTO  al trattamento dei miei dati personali."
                                styles={{ backgroundColor: 'rgba(255,255,255,0.9)', padding: '1rem' }}
                            />
                        </div>
                    </Intro>
                </StyledContainer>
            </TopSection>
            <StyledContainer type="xxLarge">
                <Features>
                    <Feature>
                        <h3>Brutti voti in matematica, inglese, latino o greco? Corri ai ripari!</h3>
                        <p>
                            Se hai preso <strong>un brutto voto a scuola</strong>, non devi preoccuparti più di tanto.
                            Quello che conta davvero è capire quali sono state le cause e{' '}
                            <strong>correre subito ai ripari</strong>. Con Grandi Scuole{' '}
                            <strong>recuperi velocemente le insufficienze</strong> nelle materie più difficili senza
                            rischiare di accumulare debiti formativi. Abbiamo aiutato migliaia di studenti e{' '}
                            <strong>sappiamo come si fa!</strong>
                        </p>
                        <p style={{ display: 'inline-flex' }}>
                            Togliti il pensiero,{' '}
                            <TextButton
                                onClick={() => {
                                    formRef.current?.scrollIntoView()
                                    PubSub.publish('FORM_SUBMIT')
                                }}>
                                clicca qui!
                            </TextButton>
                        </p>
                    </Feature>
                    <Feature>
                        <h3>Campioni del servizio in Italia</h3>
                        <p>
                            Per il quarto anno consecutivo Grandi Scuole ha ricevuto il riconoscimento come{' '}
                            <strong>Miglior Istituto italiano per il Recupero Scolastico e Doposcuola 2022</strong>.
                            Grandi Scuole è in tutta Italia e i servizi sono attivi per le{' '}
                            <strong>scuole medie inferiori</strong> e per{' '}
                            <strong>tutti gli indirizzi di studio delle superiori.</strong>E ricorda: con Grandi Scuole
                            <strong>sei Promosso o Ripreparato</strong>.
                        </p>
                        <p style={{ display: 'inline-flex' }}>
                            Provaci!{' '}
                            <TextButton
                                onClick={() => {
                                    formRef.current?.scrollIntoView()
                                    PubSub.publish('FORM_SUBMIT')
                                }}>
                                Clicca qui!
                            </TextButton>
                        </p>
                    </Feature>
                </Features>
            </StyledContainer>
            <StyledContainer type="xxLarge">
                <Divider />
            </StyledContainer>
            <StyledContainer type="xLarge">
                <GridFeatures>
                    {features.map((item, index) => (
                        <div
                            key={`grandi-scoule-feature${index}`}
                            style={{
                                padding: '0 1rem',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                                flexDirection: 'column',
                            }}>
                            <img style={{ maxWidth: '12rem', maxHeight: '10rem' }} src={item.img} alt="WeStudents" />
                            <p>{item.label}</p>
                        </div>
                    ))}
                </GridFeatures>
            </StyledContainer>
            <Footer>
                <p>
                    Grandi Scuole - Studium srl a socio unico. - Via Matera 18, 00182 Roma - C.F. e P.Iva: 13343471002
                </p>
            </Footer>
        </div>
    )
}

export default GrandiScuole
