const LOGGERS = {
    Console: 'CONSOLE',
    Gtag: 'GTAG',
}

/** Logger */
const log = (
    data: {
        eventName: string
        params?: any
    },
    loggers = [LOGGERS.Gtag, LOGGERS.Console],
) => {
    if (!process.env.REACT_APP_ANALITYCS_ENABLED) return

    try {
        if (loggers.includes(LOGGERS.Console)) {
            console.log(`[LOGGER] Tracking event: ${data.eventName}`, data.params)
        }

        if (loggers.includes(LOGGERS.Gtag)) {
            if (typeof window !== 'undefined' && window.dataLayer) {
                window.dataLayer.push({ event: data.eventName, ...data.params })
            }
        }
    } catch (error) {
        console.error(`[LOGGER] Error tracking event: ${data.eventName}`, error)
    }
}

const analytics = { log, LOGGERS }

export default analytics
